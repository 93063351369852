var map, loc, curLat, curLon;
var directionsDisplay;
var directionsService = new google.maps.DirectionsService();

function googleMapInitialize() {
    directionsDisplay = new google.maps.DirectionsRenderer();

    var customMapType = new google.maps.StyledMapType();

    var mapOptions = {
        zoom: 20,
        scrollwheel: false,
        center: new google.maps.LatLng(38.7414383,-90.587339)
    }
    var map = new google.maps.Map(document.getElementById('map'), mapOptions);
    directionsDisplay.setMap(map);

    var image = 'img/flag.png';
    var myLatLng = new google.maps.LatLng(38.7414383,-90.587339);

    var beachMarker = new google.maps.Marker({
        position: myLatLng,
        map: map
    });

    google.maps.event.addListener(beachMarker, 'click', function() {
        window.open("https://www.google.com/maps/place/3902+Old+Hwy+94+S,+St+Charles,+MO+63304,+USA/@38.741362,-90.589081,17z/data=!3m1!4b1!4m5!3m4!1s0x87df29f3e4ed91cd:0xfebb4db10ad3da0!8m2!3d38.741362!4d-90.586887");
    });
}
window.onload = function() {
    googleMapInitialize();
};